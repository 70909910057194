import { default as indexkoivs8q9ZbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexfKa4cA2aWoMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue?macro=true";
import { default as indexMegK8NABVeMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/articles/index.vue?macro=true";
import { default as indexWV8eR38zLKMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/cart/index.vue?macro=true";
import { default as completefpy0SquUgUMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/complete.vue?macro=true";
import { default as loginVQAy4GZNzzMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/login.vue?macro=true";
import { default as indexk89KPbqs69Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue?macro=true";
import { default as indexuQREynsiCaMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/events/index.vue?macro=true";
import { default as _91id_93IWwYrHPVI3Meta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue?macro=true";
import { default as _91id_93XVGWHcUqiOMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue?macro=true";
import { default as indexm1semfiI8YMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/index.vue?macro=true";
import { default as islands_45pageHIB3DRLxLuMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue?macro=true";
import { default as indexpzCPJ6kWkIMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue?macro=true";
import { default as indexYVga7PhUChMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue?macro=true";
import { default as index9tPdmztHdEMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue?macro=true";
import { default as indexDpT5sHdjWyMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/press-releases/index.vue?macro=true";
import { default as _91paragraphUuid_93YGhs5pMuRUMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/print/paragraph/[nid]/[paragraphUuid].vue?macro=true";
import { default as indexlIvEVVgGTbMeta } from "/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue?macro=true";
import { default as _91_46_46_46slug_936K9nWRs1zmMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/[id]/[...slug].vue?macro=true";
import { default as indexathjh4gz02Meta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/index.vue?macro=true";
import { default as index7nwxEY0CRRMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/account-exists/index.vue?macro=true";
import { default as indexdID2vVnt6vMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/index.vue?macro=true";
import { default as indexlmNudOYy5QMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/number-not-found/index.vue?macro=true";
import { default as indexbCL9fKgKJSMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/switch-active-account/media/[id]/index.vue?macro=true";
import { default as indexKWJ6F2Na8ZMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/administration/index.vue?macro=true";
import { default as indexpJIf2s9ULVMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/contact/index.vue?macro=true";
import { default as indexz4HtAkrcUZMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/index.vue?macro=true";
import { default as indexxQt6J8t9CrMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/pharmacies-overview/index.vue?macro=true";
import { default as _91id_93PWmlmvL259Meta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id].vue?macro=true";
import { default as indextJzC35YUO5Meta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/activation/[uid]/[timestamp]/[hash]/login/index.vue?macro=true";
import { default as index8GptEvfL6qMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/login/index.vue?macro=true";
import { default as indexopIpOlRKgjMeta } from "/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/password/index.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexkoivs8q9ZbMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/[...slug]/index.vue")
  },
  {
    name: "article-slug",
    path: "/artikel/:slug(.*)*",
    meta: indexfKa4cA2aWoMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/article/[...slug]/index.vue")
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexMegK8NABVeMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/articles/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexWV8eR38zLKMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/cart/index.vue")
  },
  {
    name: "checkout-complete",
    path: "/checkout/:orderId()/complete",
    meta: completefpy0SquUgUMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/complete.vue")
  },
  {
    name: "checkout-login",
    path: "/checkout/:orderId()/login",
    meta: loginVQAy4GZNzzMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/checkout/[orderId]/login.vue")
  },
  {
    name: "event-nid-slug",
    path: "/event/:nid/:slug(.*)*",
    meta: indexk89KPbqs69Meta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/event/[nid]/[...slug]/index.vue")
  },
  {
    name: "pharmasuisse-events-overview",
    path: "/events",
    meta: indexuQREynsiCaMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/events/index.vue")
  },
  {
    name: "webform",
    path: "/form/:id()",
    meta: _91id_93IWwYrHPVI3Meta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/[id].vue")
  },
  {
    name: "webform-confirmation",
    path: "/form/confirmation/:id()",
    meta: _91id_93XVGWHcUqiOMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/form/confirmation/[id].vue")
  },
  {
    name: "home",
    path: "/",
    meta: indexm1semfiI8YMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/index.vue")
  },
  {
    name: "islands-page",
    path: "/islands-page",
    meta: islands_45pageHIB3DRLxLuMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/islands-page.vue")
  },
  {
    name: "media-id",
    path: "/media/:id()",
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/media/[id]/index.vue")
  },
  {
    name: "node-canonical",
    path: "/node/:nid()",
    meta: indexYVga7PhUChMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/node/[nid]/index.vue")
  },
  {
    name: "press-release-slug",
    path: "/medienmitteilung/:slug(.*)*",
    meta: index9tPdmztHdEMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/press-release/[...slug]/index.vue")
  },
  {
    name: "press-releases",
    path: "/press-releases",
    meta: indexDpT5sHdjWyMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/press-releases/index.vue")
  },
  {
    name: "print-paragraph",
    path: "/print/paragraph/:nid()/:paragraphUuid()",
    meta: _91paragraphUuid_93YGhs5pMuRUMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/print/paragraph/[nid]/[paragraphUuid].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexlIvEVVgGTbMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/pages/search/index.vue")
  },
  {
    name: "shop-product",
    path: "/shop/:id()/:slug(.*)*",
    meta: _91_46_46_46slug_936K9nWRs1zmMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/[id]/[...slug].vue")
  },
  {
    name: "shop",
    path: "/shop",
    meta: indexathjh4gz02Meta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/shop/index.vue")
  },
  {
    name: "signup-account-exists",
    path: "/signup/account-exists",
    meta: index7nwxEY0CRRMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/account-exists/index.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: indexdID2vVnt6vMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/index.vue")
  },
  {
    name: "signup-number-not-found",
    path: "/signup/number-not-found",
    meta: indexlmNudOYy5QMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/signup/number-not-found/index.vue")
  },
  {
    name: "switch-active-account-media-id",
    path: "/aktives-konto-wechseln/media/:mediaId(\\d+)",
    meta: indexbCL9fKgKJSMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/switch-active-account/media/[id]/index.vue")
  },
  {
    name: "user-route",
    path: "/user/:id()",
    meta: _91id_93PWmlmvL259Meta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id].vue"),
    children: [
  {
    name: "user-administration",
    path: "administration",
    meta: indexKWJ6F2Na8ZMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/administration/index.vue")
  },
  {
    name: "user-id-contact",
    path: "contact",
    meta: indexpJIf2s9ULVMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/contact/index.vue")
  },
  {
    name: "user-overview",
    path: "",
    meta: indexz4HtAkrcUZMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/index.vue")
  },
  {
    name: "user-pharmacies-overview",
    path: "pharmacies-overview",
    meta: indexxQt6J8t9CrMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/[id]/pharmacies-overview/index.vue")
  }
]
  },
  {
    name: "user-activation-uid-timestamp-hash-login",
    path: "/user/activation/:uid()/:timestamp()/:hash()/login",
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/activation/[uid]/[timestamp]/[hash]/login/index.vue")
  },
  {
    name: "login",
    path: "/user/login",
    meta: index8GptEvfL6qMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/login/index.vue")
  },
  {
    name: "user-password",
    path: "/user/password",
    meta: indexopIpOlRKgjMeta || {},
    component: () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/pages/user/password/index.vue")
  }
]